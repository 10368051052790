// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
    margin: 0;
    font-size: 1.5em;
    color: #d8190c;
  }

h4 {
  font-size: 1.2em;
  color: #fcfcfc;
}

h1 {
  font-size: 2em;
  color: #fcfcfc;
}

h2 {
  font-size: 1.5em;
  color: #fcfcfc;
}

p {
  font-size: 1.5em;
  color: #9b9898;
}`, "",{"version":3,"sources":["webpack://./src/AboutPage.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,gBAAgB;IAChB,cAAc;EAChB;;AAEF;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["h3 {\n    margin: 0;\n    font-size: 1.5em;\n    color: #d8190c;\n  }\n\nh4 {\n  font-size: 1.2em;\n  color: #fcfcfc;\n}\n\nh1 {\n  font-size: 2em;\n  color: #fcfcfc;\n}\n\nh2 {\n  font-size: 1.5em;\n  color: #fcfcfc;\n}\n\np {\n  font-size: 1.5em;\n  color: #9b9898;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
