// src/BusinessInfo.js
import React from 'react';
import './BusinessInfo.css';

const BusinessInfo = () => {
  return (
    <div className="business-info">
      <h2>Business Hours</h2>
      <p>Available by email during regular business hours.</p>
      <p> 9:00 AM - 5:00 PM</p> 
      <p>Monday - Friday</p>
      <h2>Contact Us:</h2>
      {/* <p>Phone: (317) 999-5051</p> */}
      <p>Email: admin@adsolive.com</p>
    </div>
  );
};

export default BusinessInfo;
