// StarryBackground.js
import React from 'react';
import './StarryBackground.css'; // Make sure to import the CSS

const StarryBackground = () => {
  const stars = Array.from({ length: 100 }, (_, i) => ({
    id: i,
    x: Math.random() * window.innerWidth,
    y: Math.random() * window.innerHeight,
  }));

  return (
    <div className="starry-background">
      {stars.map(star => (
        <div
          key={star.id}
          className="star"
          style={{
            left: `${star.x}px`,
            top: `${star.y}px`,
            animationDuration: `${Math.random() * 3 + 2}s`, // Randomize animation speed
          }}
        />
      ))}
    </div>
  );
};

export default StarryBackground;
