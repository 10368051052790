// src/Header.js
import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <img src="AdsoLive.jpg" alt="Left Logo" className="header-logo" />
        <div className="header-text">
          <h1> Adso Live </h1>
          <p>Indianapolis, IN</p>
        </div>
        <img src="AdsoLive.jpg" alt="Right Logo" className="header-logo" />
      </div>
    </header>
  );
};

export default Header;

