// src/PhotoSection.js
import React from 'react';
import './PhotoSection.css';
// import StarsBackground from './StarsBackground';
import StarryBackground from './components/StarryBackground';

const PhotoSection = () => {
  return (
    <div className="photo-section">
      <StarryBackground /> 
      <img src="adso.jpg" alt="Business 1" />
      
    </div>
  );
};

export default PhotoSection;
