// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your App.css or a dedicated CSS file */
@keyframes twinkle {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  .starry-background {
    position: fixed;  /* Change from absolute to fixed to cover the entire viewport */
    top: 0;
    left: 0;
    width: 100vw;  /* Ensure it spans the full viewport width */
    height: 100vh;  /* Ensure it spans the full viewport height */
    background: black;  /* Set a black background */
    overflow: hidden;
    z-index: -1;
    pointer-events: none;  /* Ensure it does not block interactions */
  }
  
  
  .star {
    position: absolute;
    border-radius: 50%;
    background-color: white;
    width: 3px;
    height: 3px;
    animation: twinkle 2s infinite ease-in-out;
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/StarryBackground.css"],"names":[],"mappings":"AAAA,qDAAqD;AACrD;IACI;MACE,UAAU;IACZ;IACA;MACE,YAAY;IACd;EACF;;EAEA;IACE,eAAe,GAAG,+DAA+D;IACjF,MAAM;IACN,OAAO;IACP,YAAY,GAAG,4CAA4C;IAC3D,aAAa,GAAG,6CAA6C;IAC7D,iBAAiB,GAAG,2BAA2B;IAC/C,gBAAgB;IAChB,WAAW;IACX,oBAAoB,GAAG,0CAA0C;EACnE;;;EAGA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;IACvB,UAAU;IACV,WAAW;IACX,0CAA0C;IAC1C,YAAY;EACd","sourcesContent":["/* Add this to your App.css or a dedicated CSS file */\n@keyframes twinkle {\n    0%, 100% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0.5;\n    }\n  }\n  \n  .starry-background {\n    position: fixed;  /* Change from absolute to fixed to cover the entire viewport */\n    top: 0;\n    left: 0;\n    width: 100vw;  /* Ensure it spans the full viewport width */\n    height: 100vh;  /* Ensure it spans the full viewport height */\n    background: black;  /* Set a black background */\n    overflow: hidden;\n    z-index: -1;\n    pointer-events: none;  /* Ensure it does not block interactions */\n  }\n  \n  \n  .star {\n    position: absolute;\n    border-radius: 50%;\n    background-color: white;\n    width: 3px;\n    height: 3px;\n    animation: twinkle 2s infinite ease-in-out;\n    opacity: 0.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
