// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/LinksPage.css */
.link-container {
    margin-bottom: 1rem;
  }
  
  .link {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    margin: 10px 0;
    display: inline-block;

  }
  
  .description {
    display: inline;
    margin-left: 1rem;
    color: #e9e4e4;
  }
  
  h1 {
    margin: 20px 0;
    padding: 20px 0;
    font-size: 2.5em;
    color: #9b9898;
    text-decoration-line: underline;
  }

  h5 {
    margin: 20px 0;
    padding: 20px 0;
    font-size: .8em;
    color: #8a8ca8;
    text-decoration-line: underline;
  }

  p {
    font-size: 1.5em;
    color: #9b9898;
  }`, "",{"version":3,"sources":["webpack://./src/LinksPage.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,qBAAqB;;EAEvB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,+BAA+B;EACjC;;EAEA;IACE,cAAc;IACd,eAAe;IACf,eAAe;IACf,cAAc;IACd,+BAA+B;EACjC;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":["/* src/LinksPage.css */\n.link-container {\n    margin-bottom: 1rem;\n  }\n  \n  .link {\n    font-size: 1.2rem;\n    font-weight: bold;\n    text-decoration: none;\n    margin: 10px 0;\n    display: inline-block;\n\n  }\n  \n  .description {\n    display: inline;\n    margin-left: 1rem;\n    color: #e9e4e4;\n  }\n  \n  h1 {\n    margin: 20px 0;\n    padding: 20px 0;\n    font-size: 2.5em;\n    color: #9b9898;\n    text-decoration-line: underline;\n  }\n\n  h5 {\n    margin: 20px 0;\n    padding: 20px 0;\n    font-size: .8em;\n    color: #8a8ca8;\n    text-decoration-line: underline;\n  }\n\n  p {\n    font-size: 1.5em;\n    color: #9b9898;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
