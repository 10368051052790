import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>This is the website about websites!</p>
      <p>We love technology and love following 
        the latest trends in web development.</p>
      <p>We are excited to share our knowledge with you.</p>
      {/* skip a few lines to add space */}
        <h4>Please feel free to contact us at:</h4>
        <h3>
            <a href="mailto:admin@adsolive.com" style={{ textDecoration: 'none', color: 'blue' }}>admin@adsolive.com</a>
        </h3>
        <h4>Or visit some of our partner sites!</h4>
        <h3>
            <a href="https://www.twinpyros.com" style={{ textDecoration: 'none', color: 'blue' }}>www.TwinPyros.com</a>
        </h3>
        <h3>
            <a href="https://www.animpairedlove.org" style={{ textDecoration: 'none', color: 'blue' }}>www.AnImpairedLove.org</a>
        </h3>
        <div>
        <h4>PCEP Certifications</h4>
        <a href="https://verify.openedg.org"
          style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
          target="_blank"
          rel="noopener noreferrer">
            Python Institute Certification
        </a>
        <h5>(Please email for verification code.)</h5>
      </div>

      </div>
    );
}





export default AboutPage;

