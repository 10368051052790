// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './MessageBoard.css'; // Import the CSS file

// function MessageBoard() {
//     const [messages, setMessages] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchMessages = async () => {
//             const token = process.env.REACT_APP_API_TOKEN;
//             const headers = {
//                 Authorization: `Token ${token}`,
//             };

//             try {
//                 const response = await axios.get('https://www.animpairedlove.org/beautiful_glen/api/list/', { headers });
//                 if (Array.isArray(response.data)) {
//                     console.log('Fetched messages:', response.data); // Log the response data for debugging
//                     setMessages(response.data);
//                 } else {
//                     setError('Received data is not an array');
//                 }
//             } catch (err) {
//                 console.error('Error fetching messages:', err);
//                 setError('Failed to fetch messages');
//             }
//             setLoading(false);
//         };

//         fetchMessages();
//     }, []);

//     if (loading) {
//         return <p>Loading messages...</p>;
//     }

//     if (error) {
//         return <p>{error}</p>;
//     }

//     return (    
//         <div className="message-board">         
//             <h2>Messages from Admin</h2>
//             <ul className="message-list">
//                 {messages.map((msg) => (
//                     <li key={msg.id} className="message-item">
//                         <p>{msg.text}</p> {/* Updated field to msg.text */}
//                         <span className="message-date">{new Date(msg.created_at).toLocaleString()}</span>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// }

// export default MessageBoard;

import React, { useState } from 'react';
import garyImage from './gary.jpg'; // Adjust the path if necessary

function VideoPreview() {
    const [showVideo, setShowVideo] = useState(false);

    const handlePlayClick = () => {
        setShowVideo(true);
    };

    return (    
        <div className="video-preview">
            <h2>V - Message Preview</h2>

            {!showVideo ? (
                <div onClick={handlePlayClick}>
                    <img 
                        src={garyImage} // Use the imported image
                        alt="Video Thumbnail"
                        className="video-thumbnail"
                    />
                    <div className="play-button">▶</div>
                </div>
            ) : (
                <video 
                    width="560" 
                    height="585"
                    controls
                    autoPlay
                >
                    <source src="/garyTips.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                // <iframe 
                //     src="https://clicks.cameo.com/f/a/h093PIaLTGJLd5MhYvYGew~~/AAQRxQA~/RgRo1GlPP0Q4aHR0cHM6Ly93d3cuY2FtZW8uY29tL3JlY2lwaWVudC82NmYxOWIzYWJmMzg2NjhlYzFjM2VjNWJXA3NwY0IKZupP5PFm_081zFIOYWRzb2xpdmVAcG0ubWVYBAAAAC0~"
                //     width="560" 
                //     height="815"
                //     frameBorder="0"
                //     allow="autoplay; encrypted-media"
                //     allowFullScreen
                //     title="Cameo Video"
                // ></iframe>
            )}
        </div>
    );
}

export default VideoPreview;
