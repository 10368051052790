// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Header from './Header';
import PhotoSection from './PhotoSection';
import BusinessInfo from './BusinessInfo';
// import ConfettiExplosion from './components/ConfettiExplosion';
import StarryBackground from './components/StarryBackground';
import Snowfall from 'react-snowfall'; // Import Snowfall
import './components/StarryBackground.css'; // Make sure to import the CSS
import LinksPage from './LinksPage';
import AboutPage from './AboutPage';

import MessageBoard from './MessageBoard';


// const App = () => {
//   const [showConfetti, setShowConfetti] = useState(false);
//   const [windowSize, setWindowSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight
//   });

//   useEffect(() => {
//     const showtimer = setTimeout(() => {
//       setShowConfetti(true);
//       const hideTimer = setTimeout(() => {
//         setShowConfetti(false);
//       }, 3000);

//       return () => clearTimeout(hideTimer);
//     }, 2000);

//     return () => clearTimeout(showtimer);
//   }, []);
const App = () => {
  const [showSnow, setShowSnow] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const showtimer = setTimeout(() => {
      setShowSnow(true);
      const hideTimer = setTimeout(() => {
        setShowSnow(false);
      }, 3000); // Adjust the time as needed for how long you want the snow effect to last

      return () => clearTimeout(hideTimer);
    }, 2000); // Delay before the snow starts

    return () => clearTimeout(showtimer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <div className="App">
      <StarryBackground />
      {/* {showSnow && <Snowfall snowflakeCount={200} />}  */}
        <Header />
        <nav className="navbar">
          <ul className="nav-list">
            <li className="nav-item"><Link to="/" className="nav-link">Welcome</Link></li>                       
            <li className="nav-item"><Link to="/about" className="nav-link">About</Link></li>
            <li className="nav-item"><Link to="/links" className="nav-link">Links</Link></li>
            <li className="nav-item"><Link to="/messages" className="nav-link">Messages</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={
            <div>
              <PhotoSection />
              <BusinessInfo />
              {showSnow && (
                <Snowfall snowflakeCount={400} width={windowSize.width} height={windowSize.height} />
              )}
              {/* {showConfetti && (
                <ConfettiExplosion width={windowSize.width} height={windowSize.height} />
              )} */}
            </div>
          } />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/links" element={<LinksPage />} />         
          <Route path="/messages" element={<MessageBoard />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;


